import { register } from "swiper/element/bundle";

export function useSwiper() {
  // client only execution
  if (!import.meta.client) {
    return;
  }

  onMounted(() => {
    register();

    const swiperEl = document.querySelector("swiper-container");

    if (!swiperEl) {
      return;
    }

    swiperEl.initialize();
  });
}
