<script lang="ts" setup>
// catch all for DatoCMS Page model
import type { PageRecord } from "~~/server/graphql/datocms/generated";
import { toHead, type ToMetaTagsType } from "vue-datocms";
import { useSwiper } from "~/composables/useSwiper";

definePageMeta({ middleware: "block-extensions" });

const { t } = useI18n();
const { params } = useRoute();

const { data } = await useFetch<PageRecord>("/api/cms/page", {
  query: { slug: params.slug || "home" },
});

if (!data.value) {
  throw create404Error();
}

useDatoSlugsToI18n(data.value._allSlugLocales!);

useHead(() => {
  if (!data.value) {
    return {};
  }

  return toHead(data.value._seoMetaTags as ToMetaTagsType);
});

useSwiper();
</script>

<template>
  <div v-if="data">
    <PageHeader v-if="data.header && data.header.title !== t('page.title.home')" :data="data.header" />
    <SectionsRenderer :sections="data.sections" :current-page-id="data.id" />
  </div>
</template>

<style scoped>

</style>
